<div
  class="flex flex-col items-center justify-start min-h-screen w-full"
>
<a href="https://bakney.com">
  <div class="flex justify-center my-12">
    <img src="/brand/logo.svg" alt="Bakney" class="w-36" />
  </div>
</a>
  <div class="flex flex-col items-center justify-center mb-12 mx-6">
    <a href="/manuale" class="text-blue-500 underline text-xl">
      Vedi il manuale delle differenze
    </a>
  </div>
    <div class="flex flex-col items-center justify-center mb-12 mx-6">
    <h1 class="text-4xl font-bold">Vedi il webinar di formazione del 09/12/2024</h1>
    <div class="relative w-full aspect-video max-w-2xl my-8">
      <video
        class="absolute top-0 left-0 m-0 p-0 w-full rounded-xl overflow-hidden"
        src="https://bakney-object-spaces.fra1.cdn.digitaloceanspaces.com/webinars/09-12-2024-WebinarAssociami.mp4"
        title="Webinar di formazione Bakney"
        controls
        controlsList="nodownload"
      >
      <track kind="captions" />
    </video>
    </div>

    <img
      src="/img/BAKNEY.png"
      alt="Bakney"
      class="min-w-[20rem] max-w-[25rem] w-full rounded-xl shadow-lg"
    />

    <div class="text-lg text-gray-700 mt-8 max-w-xl">
      Abbiamo migrato con successo tutti i tuoi dati da Associami a Bakney,
      se hai domande o bisogno di assistenza, non esitare a contattarci a
      <a href="mailto:support@bakney.com" class="text-blue-500 underline"
        >support@bakney.com</a
      >
      oppure scrivici su whatsapp al numero <b>+39 3792165951</b>.
      <br>
      <br>
      <span class="text-green-700 bg-green-100 p-2 rounded-lg flex items-center font-semibold text-sm text-center border border-green-200">
        La migrazione è stata completata con successo, puoi accedere a Bakney per utilizzare il servizio.
      </span>
      <br>
      Nel corso delle prime settimane forniremo un supporto prioritario per risolvere eventuali problemi, e per rilasciare le seguenti funzionalità nel seguente ordine:
      <ul class="list-none list-inside font-medium text-sm mt-4">
        <li class="text-green-600">✅ Supporto Multi-Sede (rilasciato il 10/12/2024)</li>
        <li class="text-yellow-600">(in lavorazione) Creazione di modelli di documenti</li>
        <li class="text-gray-300">(programmato) Sistema di notifiche avanzato</li>
        <li class="text-gray-300">(programmato) Gestione di anagrafiche esterne</li>
        <li class="text-gray-300">(programmato) Pagamenti con incasso parziale</li>
        <li class="text-gray-300">(programmato) Gestione e vendita di prodotti e servizi</li>
      </ul>
    </div>
  </div>

  <footer
    class="bg-gray-100 text-black py-16 rounded-t-3xl w-full outline outline-t outline-1 outline-gray-200"
  >
    <div class="container mx-auto px-4">
      <!-- Main Footer Content -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Brand Section -->
        <div class="space-y-4">
          <img src="/brand/logo.svg" alt="Bakney" class="h-8" />
          <div class="text-sm font-bold">Leveraging tech like never before.</div>

          <div class="text-xs space-y-1">
            <p>Bakney srl</p>
            <p>P. IVA: 04521780236</p>
            <p>
              Sede Legale: C/re Aldo Moro 49, 37032, Monteforte d'Alpone (VR)
            </p>
            <p>N. REA: VR-456410 · Registro delle Imprese di Verona</p>
            <p>Capitale Sociale: € 1.000,00 i.v.</p>
          </div>
        </div>

        <!-- Resources Section -->
        <div class="md:ml-8">
          <h3 class="text-xl font-semibold mb-4">RISORSE</h3>
          <ul class="space-y-2">
            <li>
              <a href="https://bakney.com/prezzi" class="hover:underline"
                >Prezzi</a
              >
            </li>
            <li><a href="https://www.iubenda.com/privacy-policy/19465245" class="hover:underline">Privacy Policy</a></li>
            <li>
              <a href="https://www.iubenda.com/termini-e-condizioni/19465245" class="hover:underline">Termini e Condizioni</a>
            </li>
          </ul>
        </div>

        <!-- Contacts Section -->
        <div>
          <h3 class="text-xl font-semibold mb-4">CONTATTI</h3>
          <ul class="space-y-2">
            <li>
              <a href="mailto:support@bakney.com" class="hover:underline"
                >support@bakney.com</a
              >
            </li>
            <li>
              <p class="text-xs">whatsapp</p>
              <a href="tel:3792165951" class="hover:underline">+39 379 2165951</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="mt-8 text-center text-sm">
        <p>
          Copyright © {new Date().getFullYear()} - Tutti i diritti riservati a Bakney srl -
          <a href="https://bakney.com" class="hover:underline"
            >bakney.com</a
          >
        </p>
      </div>
    </div>
  </footer>
</div>
