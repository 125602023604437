<div class="flex flex-col items-center justify-start min-h-screen w-full">
  <a href="https://bakney.com">
    <div class="flex justify-center my-12">
      <img src="/brand/logo.svg" alt="Bakney" class="w-36" />
    </div>
  </a>
  <div class="flex flex-col items-center justify-center mb-12 mx-6">
    <a href="/" class="text-blue-500 underline text-xl">
      Torna alla pagina iniziale
    </a>
  </div>
  <h1 class="text-4xl font-bold text-center text-purple-700 max-w-xl">
    Manuale delle differenze tra Associami e Bakney
  </h1>
  <div class="flex flex-col items-center justify-center my-12 mx-6 w-full">
    <div class="w-full">
      <h2 class="text-4xl font-bold max-w-xl mx-auto" id="indice">Indice</h2>
    </div>
    <ul
      class="list-decimal list-inside mt-4 space-y-2 text-left max-w-xl w-full"
    >
      <li>
        <a href="#passaggio-a-bakney" class="text-blue-500 hover:underline">
          Passaggio a Bakney da Associami
        </a>
      </li>
      <li>
        <a href="#gestione-anagrafiche" class="text-blue-500 hover:underline">
          Gestione anagrafiche e Iscrizioni
        </a>
      </li>
      <li>
        <a href="#gestione-attivita" class="text-blue-500 hover:underline">
          Gestione attività e abbonamenti
        </a>
      </li>
      <li>
        <a href="#gestione-ricevute" class="text-blue-500 hover:underline">
          Gestione ricevute
        </a>
      </li>
      <li>
        <a href="#gestione-iscrizioni" class="text-blue-500 hover:underline">
          Gestione pagamenti
        </a>
      </li>
      <li>
        <a href="#gestione-collaboratori" class="text-blue-500 hover:underline">
          Gestione collaboratori
        </a>
      </li>
      <li>
        <a href="#impostazioni" class="text-blue-500 hover:underline">
          Impostazioni
        </a>
      </li>
      <li>
        <a href="#supporto" class="text-blue-500 hover:underline">
          Cosa fare se non trovo una funzione che c'era su Associami?
        </a>
      </li>
    </ul>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="passaggio-a-bakney">
      1. Passaggio a Bakney da associami
    </h2>
    <p class="text-lg text-gray-700 mt-8 max-w-xl">
      Dovresti aver già ricevuto un'email di riepilogo con tutti i dettagli. Nel
      caso non l'avessi ricevuta, puoi resettare la password da questa pagina:
      <a
        href="https://app.bakney.com/#/login?page=forgot"
        class="text-blue-500 underline">Reset password</a
      >
      Inserendo l'email con cui ti sei registrato ad associami. Una volta resettata
      la password, puoi accedere alla tua area personale di Bakney.
    </p>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold">2. Gestione anagrafiche e Iscrizioni</h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
      In Bakney le anagrafiche sono gestite in modo diverso rispetto ad
      Associami.
      <br />
      <br />
      Nella sezione <b>Organizzazione / Iscrizioni</b> trovi le iscrizioni
      all'associazione ed i tesseramenti. Di default sono visualizzate le
      iscrizioni attive, ma puoi filtrare con i tasti sopra l'elenco.
      <br />
      Le anagrafiche sono collegate alle iscrizioni, e per poter visualizzare i dati
      è sufficiente cliccare sull'iscrizione.
      <br />
      Esistono tre tipologie di iscrizioni:
      <ul class="list-disc list-inside mt-4 space-y-2">
        <li>Soci</li>
        <li>Soci e Tesserati</li>
        <li>Tesserati</li>
      </ul>
      <br />
      Puoi raccogliere le iscrizioni online, e per personalizzare il modulo di iscrizione
      è sufficente andare in
      <b>Organizzazione / Modulo Iscrizione</b> ed editare le sezioni del modulo
      come desideri.
      <br />
      Puoi impostare:
      <ul class="list-disc list-inside mt-4 space-y-2 mb-4">
        <li>tipologia iscrizione</li>
        <li>quote tesseramento</li>
        <li>quote associative</li>
        <li>sezioni del modulo</li>
        <li>campi aggiuntivi</li>
      </ul>
      Per dare accesso da un atleta alla sua anagrafica è sufficiente selezionare
      l'iscrizione dall'elenco, e cliccare su<b>Trasferisci</b>, qui se
      l'anagrafica contiene un'email il campo sarà già compilato.
      <br />
      In alternativa, puoi inserire un'altra email, l'atleta riceverà un link per
      poter creare un account su Bakney e accettare il trasferimento.
      <br />
      Così facendo l'atleta potrà vedere le iscrizioni, i pagamenti, le ricevute
      e le attività associate.
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="gestione-attivita">
      3. Gestione attività e abbonamenti
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
      In Bakney le attività sono gestite in modo diverso rispetto ad Associami.
      <br />
      Troverai sia gli abbonamenti che le attività nella sezione
      <b>Attività</b>.
      <br />
      Puoi creare 3 tipologie di attività:
      <ul class="list-disc list-inside mt-4 space-y-2">
        <li>Abbonamenti</li>
        <li>Corsi (anche con le rate)</li>
        <li>Corsi con quote multiple selezionabili</li>
      </ul>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="gestione-ricevute">
      4. Gestione delle ricevute
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
      Le ricevute sono associate ai pagamenti, e possono essere scaricate da
      <b>Documenti fiscali / ricevute </b> oppure dal tasto documento vicino ad ogni pagamento incassato.
      <br>
      La ricevuta viene creata in automatico ogni volta che un pagamento in entrata viene incassato.
      <br><br>
      Le ricevute importate da associami non sono modificabili per una questione di compatibilità.
      <br><br>
      Puoi impostare anche un nuovo inizio del numero progressivo da <b>Impostazioni / Generali</b>.<br><br>
      Se hai bisogno di aiuto apri un ticket da <b>Assistenza Tecnica</b> oppure scrivici a <a class="link text-blue-500 text-undeline" href="mailto:support@bakney.com">
        support@bakney.com
      </a>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="gestione-pagamenti">
      5. Gestione dei pagamenti
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
     Puoi gestire i pagamenti dalla sezione <b>Pagamenti</b> oppure dall'anagrafica nella sezione <b>Pagamenti</b>.
     <br>
     Per le attività, le iscrizioni e gli abbonamenti vengono creati dei pagamenti associati in automatico.
     <br>
     Puoi inserire dei pagamenti anche manualmente da entrambe le sezioni, e gestire le causali da <b>Pagamenti / Centri di Costo</b>.
     <br>
     Puoi inoltre gestire i conti finanziari come Cassa o Banca da <b>Gestione / Conti finanziari</b>.
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="gestione-collaboratori">
      6. Gestione collaboratori
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
     Puoi creare dei collaboratori dalla sezione <b>Collaboratori</b> andando a specificare con granularità i permessi.
     <br>
     Una volta cliccato su + Collaboratore, ti basta selezionare su Permessi "Accesso Personalizzato".
     <br>
     Da qui puoi impostare cosa può fare il collaboratore tra:
      <ul class="list-disc list-inside mt-4 space-y-2">
        <li>Vedi</li>
        <li>Modifica</li>
        <li>Crea</li>
        <li>Elimina</li>
      </ul>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="impostazioni">
      7. Impostazioni
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
    Puoi configurare le impostazioni generali della tua associazione da <b>Impostazioni / Profilo</b>.
    Come Intestazione e piè di pagina della ricevuta, e altro.
    <br>
    Per le altre impostazioni puoi visitare la sezione <b>Impostazioni / Generali</b>.
    Qui puoi configurare la stagione sportiva, l'anno fiscale, e molto altro.
    </div>
  </div>

  <div class="flex flex-col items-center justify-center my-6 mx-6">
    <h2 class="text-4xl font-bold" id="supporto">
      8. Ho bisogno di aiuto!
    </h2>
    <div class="text-lg text-gray-700 mt-8 max-w-xl">
      Abbiamo importato tutti i dati da Associami, ma alcune funzioni potrebberero non essere immediatamente disponibili.
      Nel corso della prima settimana di migrazione, rilascieremo giornalmente le funzioni mancanti, se hai bisogno che alcune 
      di queste siano rilasciate in anticipo, non esitare a contattarci.

      <br>
      Se riscontri discrepanze tra i dati contattaci in modo che possiamo fornirti supporto per correggerle.
    </div>
  </div>


  <footer
    class="bg-gray-100 text-black py-16 rounded-t-3xl w-full outline outline-t outline-1 outline-gray-200"
  >
    <div class="container mx-auto px-4">
      <!-- Main Footer Content -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Brand Section -->
        <div class="space-y-4">
          <img src="/brand/logo.svg" alt="Bakney" class="h-8" />
          <div class="text-sm font-bold">
            Leveraging tech like never before.
          </div>

          <div class="text-xs space-y-1">
            <p>Bakney srl</p>
            <p>P. IVA: 04521780236</p>
            <p>
              Sede Legale: C/re Aldo Moro 49, 37032, Monteforte d'Alpone (VR)
            </p>
            <p>N. REA: VR-456410 · Registro delle Imprese di Verona</p>
            <p>Capitale Sociale: € 1.000,00 i.v.</p>
          </div>
        </div>

        <!-- Resources Section -->
        <div class="md:ml-8">
          <h3 class="text-xl font-semibold mb-4">RISORSE</h3>
          <ul class="space-y-2">
            <li>
              <a href="https://bakney.com/prezzi" class="hover:underline"
                >Prezzi</a
              >
            </li>
            <li>
              <a
                href="https://www.iubenda.com/privacy-policy/19465245"
                class="hover:underline">Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="https://www.iubenda.com/termini-e-condizioni/19465245"
                class="hover:underline">Termini e Condizioni</a
              >
            </li>
          </ul>
        </div>

        <!-- Contacts Section -->
        <div>
          <h3 class="text-xl font-semibold mb-4">CONTATTI</h3>
          <ul class="space-y-2">
            <li>
              <a href="mailto:support@bakney.com" class="hover:underline"
                >support@bakney.com</a
              >
            </li>
            <li>
              <p class="text-xs">whatsapp</p>
              <a href="tel:3792165951" class="hover:underline"
                >+39 379 2165951</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="mt-8 text-center text-sm">
        <p>
          Copyright © {new Date().getFullYear()} - Tutti i diritti riservati a Bakney
          srl -
          <a href="https://bakney.com" class="hover:underline">bakney.com</a>
        </p>
      </div>
    </div>
  </footer>
</div>
